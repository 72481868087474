let resumeData = {
  imagebaseurl: 'https://github.com/aaronsomo',
  name: 'Aaron Southammavong',
  role: 'Full-Stack Mobile and Web Developer',
  linkedinId: ' https://linkedin.com/in/aaronsouthammavong',
  // skypeid: 'Your skypeid',
  roleDescription:
    '  I enjoy solving problems that challenge my understanding, as well as engaging myself in finding better solutions. With that said, I’d love the opportunity to learn more about your challenges and have a discussion about what I can do to help solve them.',
  socialLinks: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/aaronsouthammavong/',
      className: 'fa fa-linkedin',
    },
    {
      name: 'github',
      url: 'http://github.com/aaronsomo',
      className: 'fa fa-github',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/aaronek/',
      className: 'fa fa-instagram',
    },
    {
      name: 'twitter',
      url: 'http://twitter.com/aaroneksouth',
      className: 'fa fa-twitter',
    },
    {
      name: 'resume',
      url: 'https://drive.google.com/file/d/164_bmy5Ao61MyRj8fZvWrMggq3p4irsh/view?usp=sharing',
      className: 'fas fa-file-alt',
    },
  ],
  aboutme:
    `Hey there! My name's Aaron Southammavong. I'm nothing more than your average eager learner with a penchant for creating... just... things. From building lego bridges as a toddler to designing and managing the construction of real bridges in the city of Chicago; from thinking of app ideas to launching three successful apps in both the iOS App Store and Google Play Store; from eating, traveling, dancing, and laughing to making video montages of those moments - I explore multiple facets of life to create "somethings" out of "not-so-nothings." I also bake a lot and can give you a run for your money in ping pong. I truly live to enjoy life and help others do the same.`,
  address: 'ae.southammavong@gmail.com',
  phone: '+ 1 ( 8 4 7 ) 2 7 1 - 6 3 0 7',
  education: [
    {
      UniversityName: 'Hack Reactor',
      specialization: 'Software Engineering Immersive',
      MonthOfPassing: 'January',
      YearOfPassing: '2020',
      // Achievements: 'Some Achievements',
    },
    {
      UniversityName: 'University of Illinois at Chicago',
      specialization: 'Bachelor of Science in Civil Engineering',
      MonthOfPassing: 'May',
      YearOfPassing: '2013',
      // Achievements: 'Some Achievements',
    },
  ],
  work: [
    {
      CompanyName: 'Protiviti',
      link: 'https://www.protiviti.com/',
      specialization: 'Lead Software Engineer',
      // MonthOfLeaving: 'February 2022',
      // YearOfLeaving: 'September 2022',
      Achievements:
        "Working with numerous clients on architecting systems and implementing solutions.",
    },
    {
      CompanyName: 'Secret Garden',
      link: 'https://secretgarden.fm/',
      specialization: 'Founding Engineer',
      MonthOfLeaving: 'February 2022',
      YearOfLeaving: 'January 2023',
      Achievements:
        "One of the core engineers exploring the NFT space for something more tangible than a profile picture or a simple jpeg. Enabling creative accessibility to users with easy-to-use interface. Helping create the very first interactive audiovisual NFT experience, selling out producer releases with over 300+ ethereum in trading volume. Maintaining positive product direction, retaining over 70% unique NFT holders across 4 collections.",
    },
    {
      CompanyName: 'Sporttrade',
      link: 'https://www.getsporttrade.com/',
      specialization: 'Senior Software Engineer',
      MonthOfLeaving: 'May 2021',
      YearOfLeaving: 'September 2022',
      Achievements:
        "Involved in a few of Sporttrade's web applications and internal tooling. Worked on internal market operations dashboard. Created an auditing tool/web application from scratch using JavaScript and Python technologies. Helped set up a style guide and creating re-useable higher ordered components for the Sporttrade ecosystem.",
    },
    {
      CompanyName: 'Blockchain Researcher, Cryptocurrency & Stock Market Investor',
      link: 'https://www.tradingview.com/u/xatxay/',
      specialization: 'Blockchain Enthusiast & Market Investor/Trader',
      // MonthOfLeaving: 'Jan',
      // YearOfLeaving: '2019',
      Achievements:
        "An avid blockchain technology enthusiast; cryptocurrency and stock market investor and trader. Charting technical analyses on cryptocurrencies and stocks using arithmetic principles and trends, such as the accumulation/distribution lines, MACD, RSI, Elliot Wave Theory, and more. Purely as a hobby, none of it as financial advice.",
    },
    {
      CompanyName: 'FanFood',
      link: 'https://www.fanfoodapp.com/',
      specialization: 'Senior Full-Stack Software Engineer & Mobile Developer',
      MonthOfLeaving: 'July 2020',
      YearOfLeaving: 'July 2021',
      Achievements:
        'Added new features and refined existing features on the consumer PWA. Improved managing portal app for partners and clients. Worked on new features and streamlined performance on Android and iOS devices. Created an entire full-stack site for BuildingEats, a supplemental FanFood B2C venture.',
    },
    {
      CompanyName: 'Terror Apps',
      link: 'https://terror.llc/',
      specialization: 'Software Engineer & Mobile Developer',
      MonthOfLeaving: 'January 2019',
      YearOfLeaving: 'September 2019',
      Achievements:
        "Designing the web application and contributing to the publicly available PokerVault iOS application. Taking intiative in tackling UX/UI designs and decisions. Led Terror App's second mobile project in addressing mental health.",
    },
    {
      CompanyName: 'Smrtlens.com',
      specialization: 'CEO/Co-Founder',
      MonthOfLeaving: 'March 2017',
      YearOfLeaving: 'April 2020',
      Achievements:
        "Designing and implementing business operations. Providing a means of protecting peoples' eyes from technologies that emit blue-light. Managing a small team that handles order processing and distribution.",
    },
    {
      CompanyName: 'Infrastructure Engineering, Inc',
      specialization: 'Civil Engineer',
      MonthOfLeaving: 'March 2017',
      YearOfLeaving: 'September 2018',
      Achievements:
        'A part of a team that managed the construction of a safe pedestrian bridge path/walkway for the community that alleviated pedestrian congestion during peak hours. A part of a team that installed a community park for a neighborhood. Redesigned hundreds of pedestrian sidewalks and intersections to accommodate ADA standards and address drainage problems, among other issues.',
    },
    {
      CompanyName: 'Illinois Department of Transportation',
      specialization: 'Civil Engineer',
      MonthOfLeaving: 'June 2013',
      YearOfLeaving: 'March 2017',
      Achievements:
        'Assistant Resident Engineer at Lisbon Road Bridge. Managed the construction of the bridge. Alleviated congestion during peak hours. Provided a safe route of travel for pedestrians. Assistant Resident Engineer at Minooka Road bridge. Co-managed bridge to accommodate 4 lanes of travel in anticipation of highway expansion, traffic, and neighborhood growth. A part of a team that designed infrastructure projects for District 3 in the State of Illinois.',
    },
  ],
  skillsDescription: 'A quick summary of things that I frequent...',
  skillsDescription2: '(please see resume for a comprehensive list)',
  skills: [
    {
      skillname: 'JavaScript / TypeScript',
    },
    {
      skillname: 'React & React-Native',
    },
    {
      skillname: 'Swift',
    },
    {
      skillname: 'Express',
    },
    {
      skillname: 'NodeJS',
    },
    {
      skillname: 'PostgreSQL',
    },
    {
      skillname: 'MongoDB',
    },
    {
      skillname: 'PingPong / Baking',
    },
  ],
  portfolio: [
    {
      name: 'Secret Garden',
      description: 'Interactive Audiovisual Experience',
      stack: 'React, NodeJS, MongoDB, Web3js, Ethersjs, DigitalOcean',
      detailed:
        "Iterating on consumer facing user experience and user interface design to be more intuitive. Added customer-first features that retained 85% of the user base for subsequent product release. Experimented with concepts to become the world's first interactive music NFT.",
      imgurl: 'images/portfolio/secretgarden.png',
      live: 'https://secretgarden.fm',
      github: 'https://opensea.io/SecretGarden?tab=created'
    },
    {
      name: 'FanFood App',
      description: 'Mobile Application',
      stack: 'React-Native, NodeJS, PostgreSQL, TypeORM, AWS',
      detailed:
        'Optimizing the mobile application for both Android and iOS devices. Improved existing features and screens to streamline user experience. Added pickup/delivery options, order ahead and event features, and handled ordering and checkout process. Implemented backend changes that were required for all additional features, as well as optimizing previous systems.',
      imgurl: 'images/portfolio/fanfoodapp.png',
      live: 'https://apps.apple.com/us/app/fanfood-app/id1153019375',
      github: 'https://play.google.com/store/apps/details?id=com.fanfoodapp.fanfoodapp&hl=en_US&gl=US',
    },
    {
      name: 'PokerVault',
      description: 'Mobile Application',
      stack: 'SwiftUI, Redux, Core Data, VS App Center',
      detailed:
        'Worked on a small team to create a mobile application that helps users track their progress. Created analytical tools and features that displayed useful information to the user. Created the Sessions and New Session screens and functionality.',
      imgurl: 'images/portfolio/pokervault.png',
      live: 'https://apps.apple.com/us/app/pokervault/id1510490314',
      github: '',
    },
    {
      name: 'BuildingEats',
      description: 'Web Application',
      stack: 'React, Styled Components, NodeJS, PostgreSQL, TypeORM, AWS',
      detailed:
        'Created a full-stack web application for a venture that allows buildings and food services to partner up for a mobile, contactless ordering/dining solution.',
      imgurl: 'images/portfolio/buildingeats.png',
      live: 'https://order.fanfoodapp.com/buildingeats',
      github: 'https://github.com/aaronsomo/building-eats',
    },
    {
      name: 'Natours',
      description: 'Web Application',
      stack: 'React, SASS (CSS)',
      detailed:
        'Created a React implementation of an HTML mocksite from an advanced CSS & styling course. The course strictly went over HTML, CSS styling. I took it upon myself to refactor the code into React components and modularized them for re-usability.',
      imgurl: 'images/portfolio/reactnatours.png',
      live: 'https://aaronsomo.github.io/react-natours/',
      github: 'https://github.com/aaronsomo/react-natours',
    },
    {
      name: 'TourViewAR',
      description: 'Augmented Reality Mobile Application',
      stack: 'React-Native, Redux, Express, NodeJS, PostgreSQL, AWS, Heroku',
      detailed:
        'Created a mobile application that allows users to view and create 360 degree tours/images in augmented reality. Created login, create tours, camera, image upload, 360 view, 360 edit view, and text/object edit-view screens and their functionalities. Implemented camera and file upload functionality. Set up Express server and queries to a PostgreSQL database. Worked on a team that facilitated a monolithic architecture for project workflow.',
      imgurl: 'images/portfolio/tourviewarclient.png',
      live: 'https://github.com/aaronsomo/tourviewar-client',
      github: 'https://github.com/aaronsomo/tourviewar-client',
    },
    {
      name: 'Reading List',
      description: 'Command Line Application',
      stack: 'NodeJS',
      detailed:
        'Created the entire application in NodeJS. Allows users to search for books using the Google Book API and add results to their own reading list. Both time and space complexities were considered. Written under a time contraint of 48 hours. Tested with mocha/chai.',
      imgurl: 'images/portfolio/readinglistcommandline.png',
      live: 'https://www.youtube.com/watch?v=SKSFZ_Y144k',
      github: 'https://github.com/aaronsomo/google-books-node-app',
    },
    {
      name: 'HOME AWAY',
      description: 'Full-Stack Web Application & Systems Design',
      stack: 'React, Redux, Express, NodeJS, MongoDB, PostgreSQL, AWS, NGINX',
      detailed:
        "Re-created a mock of Home Away's front-end implementation. Re-designed a back-end system to support increase in traffic. Created ETL process to extract 15 million datasets into MongoDB and PostgreSQL databases. Optimized server and database to support a 400% increase in traffic to popular endpoints. Containerized & deployed server and database onto EC2 instances for separate management of micro-services.",
      imgurl: 'images/portfolio/sdc.png',
      live: 'https://www.youtube.com/watch?v=O07YBywin34',
      github: 'https://github.com/objectobject-hr/sdc-service-aaron',
    },
    {
      name: 'IKEA MOCK',
      description: 'Full-Stack Web Application',
      stack: 'React, Express, NodeJS, MongoDB, PostgreSQL, Heroku',
      detailed:
        'Worked on a small team to design the IKEA product page. Built raw image carousels and modal components. Generated unique datasets to populate web application through simulated endpoints. Used Mongoose ORM and MongoDB to handle non-relational data set. Implemented a micro-service oriented architecture by combining multiple micro-services into a single proxy.',
      imgurl: 'images/portfolio/ikeamock.png',
      live: 'https://www.youtube.com/watch?v=fEuulO9LFdk',
      github: 'https://github.com/objectobject-hr/service-aaron',
    },
    {
      name: 'World Annona',
      description: 'Full-Stack Web Application',
      stack: 'React, Express, NodeJS, PostgreSQL, AWS',
      detailed:
        "Created a wep application that was submitted as an entry into NASA's 2020 48-hour Space Apps Challenge Hackathon. The project aims to help end world hunger through connecting entities who have food surpluses with distribution centers in an effort to fight food waste during the Covid-19 pandemic.",
      imgurl: 'images/portfolio/annona.png',
      live: 'https://worldannona.co',
      github: 'https://github.com/No-Crop-Left-Behind',
    },
    {
      name: 'Nature Collective',
      description: 'Web Application',
      stack: 'React, SASS (CSS)',
      detailed:
        "Created a web application where users are able to search for images of nature, using any search term they'd like. It is a collection of public nature images found on Reddit.",
      imgurl: 'images/portfolio/naturecollective.png',
      live: 'https://aaronsomo.github.io/nature-collective',
      github: 'https://github.com/aaronsomo/nature-collective',
    },
  ],
  testimonials: [
    {
      description: 'This is a sample testimonial',
      name: 'Some technical guy',
    },
    {
      description: 'This is a sample testimonial',
      name: 'Some technical guy',
    },
  ],
};

export default resumeData;
